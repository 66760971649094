<template>

  <div v-if="$can('read', 'clients')">

    <client-list-add-new
      :is-add-new-client-sidebar-active.sync="isAddNewClientSidebarActive"
      :clients-list="clientsListData"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <clients-list-filters
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
        :services-filter.sync="servicesFilter"
        :services-options="servicesOptions"
      />

      <div class="m-2">

        <!-- Table Top -->
        <!-- <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.clients.fetchingClientList"
              @click="refreshClientList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh Client List</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$can('create', 'clients') && userData.group === 'Management'"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddNewClientSidebarActive = true"
              >
                <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add Client</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->

        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              :disabled="this.$store.state.clients.fetchingClientList"
              @click="refreshClientList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              v-if="$can('create', 'clients')"
              class="ml-2"
              variant="primary"
              @click="isAddNewClientSidebarActive = true"
            >
              <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add Client</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refClientListTable"
        class="position-relative"
        :items="fetchClients"
        responsive
        :fields="tableColumns"
        primary-key="clientname"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >

        <!-- Column: Client Name-->
        <template #cell(client_name)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-clients-view', params: { clientname: data.item.clientname } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.display_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.clientname }}</small>
          </div>
        </template>

        <!-- Column: Parent Name -->
        <template #cell(parent_client)="data">
          <div class="text-nowrap">
            <b-link
              v-if="data.item.parent_clientname !== undefined && data.item.parent_clientname !== ''"
              :to="{ name: 'apps-clients-view', params: { clientname: data.item.parent_clientname } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.parent_clientname }}
            </b-link>
            <span
              v-else
            >
              {{ data.item.parent_clientname }}
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveClientStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

      </b-table>
      <div
        v-if="$store.state.clients.fetchingClientList"
        class="d-flex justify-content-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalClients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import { ref, watch, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import ClientsListFilters from './ClientsListFilters.vue'
import useClientsList from './useClientsList'
import clientStoreModule from '../clientStoreModule'
import ClientListAddNew from './ClientListAddNew.vue'

export default {
  components: {
    ClientsListFilters,
    ClientListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  // data() {
  //   return {
  //     clientsListData: [],
  //   }
  // },
  props: {
    clientsListData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  computed: {
    isFetchingClientList() {
      console.log('isFetchingClientList')
      console.log(this.$store.getters.getFetchingClientList)
      return this.$store.getters.getFetchingClientList
    },
  },
  setup(props) {
    const userData = getUserData()
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const isAddNewClientSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Disabled', value: 'disabled' },
    ]
    const servicesOptions = store.state.appSettings.services

    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      clientsList,
      clientsListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientListTable,
      refetchData,

      // UI
      resolveClientRoleVariant,
      resolveClientRoleIcon,
      resolveClientStatusVariant,

      // Extra Filters
      statusFilter,
      servicesFilter,
    } = useClientsList()

    const updateClientListData = e => {
      console.log('updateClientListData')
      console.log(e)
      console.log(props.clientsListData)
      props.clientsListData.splice(0, props.clientsListData.length)
      e.forEach(item => {
        props.clientsListData.push(item)
      })
    }

    watch(clientsList, updateClientListData)

    const { router } = useRouter()
    watch(clientsListComplete, () => {
      console.log('Client List Redirect')
      console.log(clientsListComplete.value)
      console.log(clientsListComplete.value.length)
      if (clientsListComplete.value.length === 1 && (userData.group === 'Client_Admin' || userData.group === 'Client_Viewer')) {
        const nextRout = { name: 'apps-clients-view', params: { clientname: clientsListComplete.value[0].clientname } }
        console.log('Client List Redirect Route')
        console.log(nextRout)
        // Route user
        router.push(nextRout)
      }
    })

    return {
      userData,
      // Sidebar
      isAddNewClientSidebarActive,

      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      clientsList,
      clientsListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveClientRoleVariant,
      resolveClientRoleIcon,
      resolveClientStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,

      servicesOptions,
      servicesFilter,
    }
  },
  created() {
    this.$store.state.clients.fetchingClientList = true
  },
  methods: {
    refreshClientList() {
      this.$store.state.clients.fetchingClientList = true
      this.refetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
