<template>
  <div>
    <b-card-header class="pb-50">
      <h4>
        Clients List
      </h4>
      <b-form-checkbox
        v-model="showFilters"
        name="check-button"
        switch
        inline
        @change="handleShowFiltersChange"
      >
        <h5>
          Filters
        </h5>
      </b-form-checkbox>
    </b-card-header>
    <b-card-body
      v-if="showFilters"
    >
      <b-row>
        <b-col
          cols="6"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="6"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Services</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="servicesFilter"
            :options="servicesOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:servicesFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardHeader, BCardBody, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
    vSelect,
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    servicesFilter: {
      type: [String, null],
      default: null,
    },
    servicesOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showFilters: false,
    }
  },
  methods: {
    handleShowFiltersChange(checked) {
      console.log('Checkbox changed:', checked)
      // Perform actions based on `checked` state
      if (!checked) {
        this.$emit('update:servicesFilter', null)
        this.$emit('update:statusFilter', 'active')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
