import { API } from 'aws-amplify'

export default function userPreferences() {
  const saveUserPrefs = prefs => {
    let UIsettings = JSON.parse(localStorage.getItem('UIsettings'))
    UIsettings = { ...UIsettings, ...prefs }
    localStorage.setItem('UIsettings', JSON.stringify(UIsettings))
    const thePrefs = { preferences: UIsettings }
    const apiName = 'APIGateway'
    const path = '/user/preferences'

    API.patch(apiName, path, { body: thePrefs })
      .catch(error => {
        console.log(error)
      })
  }

  const getUserPref = (attributeName, defaultValue = null) => {
    const UIsettings = JSON.parse(localStorage.getItem('UIsettings'))
    // eslint-disable-next-line no-prototype-builtins
    if (UIsettings.hasOwnProperty(attributeName)) {
      return UIsettings[attributeName]
    }
    return defaultValue // Return default value if the attribute doesn't exist
  }

  return {
    saveUserPrefs,
    getUserPref,
  }
}
